<template>
  <div style="padding: 15px;" v-show="this.allow">
    <div v-text="test"></div>

    <div>
      <el-row>
        <el-col :span="24">
          <el-input placeholder="搜索项目" @change="getContracts" v-model="searchData.keyword">
            <template v-slot:append>
              <el-button>搜索</el-button>
            </template>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <div>
      <el-card v-for="(item, key) in contracts" :style="item.id === selected_id ? 'border:1px solid red;margin-top:6px;' : 'margin-top:6px;'" :key="key" @click="selectData(item)">
        <el-row>
          <el-col :span="24">
            <div><span v-text="item.simple_name"></span></div>
            <div><span>开始日期</span>：<span v-text="item.start_at"></span></div>
            <div><span>结束日期</span>：<span v-text="item.end_at"></span></div>
          </el-col>
        </el-row>
      </el-card>
    </div>

  </div>
</template>

<script>
import {apiGetAgentJssdk, apiGetJssdk} from '@/api/commonApi'
import {apiGetProjectContracts} from '@/api/shareWeworkProjectApi'
import * as ww from "@wecom/jssdk"

export default {
  created() {
    if (this.$route.query.token) {
      this.allow = true
      this.key = this.$route.query.key
      this.init()
      this.getContracts()
    }
  },
  data() {
    return {
      test: '',
      key: '',
      selected_id: '',
      searchData:{
        keyword: '',
      },
      allow: false,
      contracts: [],
      budget: [],
      jsapi: ['saveApprovalSelectedItems', 'getApprovalSelectedItems'],
    }
  },
  methods: {
    // 获取合同
    getContracts() {
      apiGetProjectContracts({
        per_page: 500,
        page: 1,
        company_id: this.$route.query.company_id,
        keyword: this.searchData.keyword,
      }).then(rsp => {
        this.contracts = rsp.data
      })
    },
    init() {
      this.ua = navigator.userAgent.toLowerCase()
      if (/wxwork/i.test(this.ua)) {
        ww.register({
          corpId: 'ww07f39146d675193a',
          agentId: '1000003',
          jsApiList: this.jsapi,
          getConfigSignature: async (url) => {
            return await apiGetJssdk({
              url: url,
              apiList: this.jsapi,
            })
          },
          getAgentConfigSignature: async (url) => {
            return await apiGetAgentJssdk({
              url: url,
              apiList: this.jsapi,
            })
          },
          onConfigFail: (data) => {
            this.test = data
            // this.text = '还未支持此登陆方式(01)'
          },
          onAgentConfigFail: (data) => {
            this.test = data
            // this.text = '还未支持此登陆方式(01)'
          },
        })
      }
    },
    selectData (value) {
      ww.saveApprovalSelectedItems({
        key: this.key,
        selectedData: [{
          key: value.id.toString(),
          value: value.name,
        }],
        success: () => {
          this.selected_id = value.id
        },
        fail: (res) => {
          this.test = res
        }
      })
    }
  }

}
</script>
